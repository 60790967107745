


















































































































import { MutationPayload } from 'vuex';
import Modal from '@/components/modal.vue';
import Input from '@/components/form/input.vue';
import Checkbox from '@/components/form/checkbox.vue';
import Textarea from '@/components/form/textarea.vue';
import store from '@/store';
import router from '@/router';
import { IProduct, IProductItem } from '@/models/product';
import { getFormFields, getFormQuestions, getProductForms } from '@/api/forms';
import { IFormQuestion } from '@/models/form';
import { deleteFile, getFileById, uploadFile } from '@/api/files';
import Cropper from '@/components/form/cropper.vue';
import {
  getSubCategories,
  getCategories,
  addProductCategory,
  addProductSubCategory,
  deleteProductSubCategory,
  deleteProductCategory,
} from '@/api/categories';
import { ICheckbox } from '@/components/form';
import { ICategory, IProductSubcategory } from '@/models/category';
import { deleteProduct, getProduct, getProductItem } from '@/api/products';
import { formInputs, modal, statusSelect } from '../index';
import { computed, defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';

export default defineComponent({
  components: {
    Modal,
    Input,
    Textarea,
    Checkbox,
    Cropper,
  },
  data() {
    return {
      modal: { ...modal },
      modalCropper: { isActive: false, isCard: true },
      product: {} as IProduct,
      questions: [] as IFormQuestion[],
      inputs: [...formInputs],
      categoryInputs: [] as (ICheckbox & { children?: ICheckbox[] })[],
      statusSelect: statusSelect(),
      event: 0,
      exhibitor: 0,
      loading: false,
      cropper: {
        image: '',
        onSelect: (blob: string) => {
          console.log(blob);
        },
        aspectRatio: 4 / 3,
      },
      cropping: true,
      croppedFileName: '',
      confirming: false,
      cropSrc: '',
      images: [] as File[],
      imageIndex: -1,
      categories: [] as ICategory[],
      productSubCategories: [] as { id: number; active: boolean }[],
      productCategories: [] as { id: number; active: boolean }[],
      productItem: {} as IProductItem,
      productId: 0,
      loadingDelete: false,
      deletedImages: [] as number[],
    };
  },

  setup() {
    const { translations } = useContext();
    const products = computed(() => store.getters.getProducts as IProduct[]);
    return { translations, products };
  },

  async created() {
    this.productId = parseInt(this.$route.params.product, 10);
    this.event = parseInt(this.$route.params.event, 10);
    this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
    this.cropper.onSelect = this.onSelectCrop;

    if (this.modal.close) {
      this.modal.close = this.modal.close.bind({}, this.productId);
    }
    const { data } = await getSubCategories({ event: this.event });
    const pResponse = await getProductItem(this.productId);
    this.productItem = pResponse.data;

    if (data && data.results) {
      const subCategories = data.results;
      getCategories({ event: this.event }).then((response) => {
        this.categories = response.data.results;
        this.categories.forEach((category) => {
          const inputChildren = [] as ICheckbox[];

          const children = subCategories.filter(
            (subCategory) => subCategory.category === category.id,
          );
          const categoryActive =
            this.productItem.categories &&
            this.productItem.categories.some((pCategory) => pCategory.category === category.id);

          if (children && children.length > 0) {
            children.forEach((subCategory) => {
              const subCategoryActive =
                this.productItem.categories &&
                this.productItem.categories.some((pCategory) =>
                  pCategory.subcategories.some(
                    (subProductCategory) => subProductCategory.subcategory === subCategory.id,
                  ),
                );

              inputChildren.push({
                id: `product-subcategory_${subCategory.id}`,
                name: `product_subcategory_${subCategory.id}`,
                value: !!subCategoryActive,
                type: 'checkbox',
                label: subCategory.name,
                onClick: (value: string) => {
                  const _subCategory = this.productSubCategories.find(
                    (_subCategory) => _subCategory.id === subCategory.id,
                  );
                  if (value === 'on') {
                    if (_subCategory) {
                      const catIndex = this.productSubCategories.indexOf(_subCategory);
                      this.productSubCategories[catIndex] = { id: subCategory.id, active: true };
                    } else {
                      this.productSubCategories.push({ id: subCategory.id, active: true });
                    }
                  } else if (_subCategory) {
                    const catIndex = this.productSubCategories.indexOf(_subCategory);
                    this.productSubCategories[catIndex] = { id: subCategory.id, active: false };
                  } else {
                    this.productSubCategories.push({ id: subCategory.id, active: false });
                  }
                },
              });
            });
          }
          this.categoryInputs.push({
            id: `product-category_${category.id}`,
            name: `product_category_${category.id}`,
            value: !!categoryActive,
            type: 'checkbox',
            label: category.name,
            onClick: (value: string) => {
              const _category = this.productCategories.find(
                (pCategory) => pCategory.id === category.id,
              );
              if (value === 'on') {
                if (_category) {
                  const catIndex = this.productCategories.indexOf(_category);
                  this.productCategories[catIndex] = { id: category.id, active: true };
                } else {
                  this.productCategories.push({ id: category.id, active: true });
                }
              } else if (_category) {
                const catIndex = this.productCategories.indexOf(_category);
                this.productCategories[catIndex] = { id: category.id, active: false };
              } else {
                this.productCategories.push({ id: category.id, active: false });
              }
            },
            children: inputChildren,
          });
        });
        this.$forceUpdate();
      });
    }
    this.getProduct();
    const unsubscribe = store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setProducts') {
        this.getProduct();
        unsubscribe();
      }
    });
  },
  methods: {
    async getProduct() {
      const productId = parseInt(this.$route.params.product, 10);
      const product = this.products.find((product) => product.id === productId);
      if (product) {
        this.product = product;
        this.statusSelect = statusSelect();
        this.statusSelect.value = this.product.submitted;
        getFormFields(this.product.form).then((response) => {
          const fields = response.data.results;
          getProductForms(this.event).then((response) => {
            if (response.data.results[0].id) {
              const formId = response.data.results[0].id;
              getFormQuestions(formId).then((response) => {
                this.questions = response.data.results;
                this.inputs = [...formInputs];
                this.questions.forEach((question, i) => {
                  const field = fields.find((field) => question.form_field === field.id);
                  if (product.answers && question && field) {
                    const answer = product.answers.find(
                      (answer) => answer.question && answer.question.form_field === field.id,
                    );
                    const value = answer ? answer.value : '';
                    const index = i;

                    if (field.type_field > 5) {
                      if (value !== '' && value !== 'undefined') {
                        this.inputs[index] = {
                          id: `exhibitor-${question.id}`,
                          name: `exhibitor_${question.id}`,
                          label: question.display_name,
                          placeholder: question.placeholder,
                          type: 'file',
                          onFileSelect: (file: File) => {
                            this.selectImage(file, index);
                          },
                          value: '',
                        };
                        this.$forceUpdate();

                        getFileById(value).then((response) => {
                          const url = response.data.file;
                          const filename = response.data.original_file_name;
                          this.inputs[index] = {
                            ...this.inputs[index],
                            message: url,
                            onDelete: () => {
                              this.deletedImages.push(index);
                              this.inputs[index] = { ...this.inputs[index], message: undefined };
                              this.$forceUpdate();
                            },
                            value: { name: filename || '' } as File,
                          };
                          this.questions[i].answer = { name: filename || '' } as any;

                          this.$forceUpdate();
                        });
                      } else {
                        this.inputs[index] = {
                          id: `exhibitor-${field.id}`,
                          name: `exhibitor_${field.id}`,
                          label: question.display_name,
                          placeholder: question.placeholder,
                          type: 'file',
                          message: question.required
                            ? this.translations.common.mandatory_field
                            : undefined,

                          onFileSelect: (file: File) => {
                            this.selectImage(file, index);
                          },
                          value,
                        };
                      }
                    } else {
                      this.inputs[index] = {
                        id: `exhibitor-${field.id}`,
                        name: `exhibitor_${field.id}`,
                        label: question.display_name,
                        placeholder: question.placeholder,
                        type: field.name === 'Description' ? 'textarea' : 'text',
                        message: question.required
                          ? this.translations.common.mandatory_field
                          : undefined,
                        onInput: (value: string | number) => this.clearInput(index, value),
                        value,
                      };
                    }

                    this.$forceUpdate();
                  }
                });
              });
            }
          });
        });
      } else {
        store.dispatch('fetchProducts', {
          event: this.event,
          page: 1,
          page_size: 1000,
          exhibitor: this.exhibitor,
        });
      }
    },

    selectImage(file: File, index: number) {
      this.croppedFileName = file.name;
      if (file.name.match(/.(jpg|jpeg|png|gif)$/i)) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e && e.target && e.target.result) {
            this.cropper = { ...this.cropper, image: e.target.result as string };
            this.cropping = true;
            this.confirming = false;
            this.modalCropper.isActive = true;
            this.imageIndex = index;
          }
        };
        reader.readAsDataURL(file);
      } else {
        this.inputs[index] = {
          ...this.inputs[index],
          value: '',
          message: this.translations.common.file_not_image,
          error: true,
        } as any;
        this.$forceUpdate();
      }
    },

    onSelectCrop(blob: string) {
      const file = new File([blob], this.croppedFileName, { type: 'image/png' });
      const urlCreator = window.URL || window.webkitURL;
      this.cropSrc = urlCreator.createObjectURL(blob);
      this.images[this.imageIndex] = file;
      this.cropping = false;
      this.confirming = true;
    },

    confirmCrop() {
      this.modalCropper.isActive = false;
    },

    cancel() {
      this.modalCropper.isActive = false;
    },

    cancelCrop() {
      this.cropping = true;
      this.confirming = false;
    },

    clearInput(index: number, value: string | number) {
      if (this.inputs[index].error) {
        this.inputs[index] = {
          ...this.inputs[index],
          error: false,
          message: '',
          value: value as string,
        };
        this.$forceUpdate();
      }
    },

    async deleteAction() {
      this.loadingDelete = true;
      await deleteProduct(this.productId);
      this.loadingDelete = false;
      router.push(`/event/${this.event}/manage-exhibitor/${this.exhibitor}/products`);
    },

    async editAction() {
      this.loading = true;

      for (const index in this.images) {
        const image = this.images[index];
        const imageForm = new FormData();
        imageForm.append('file', image);
        const response = await uploadFile(imageForm);
        this.questions[index].answer = `${response.data.id}`;
      }

      for (const index in this.deletedImages) {
        await deleteFile(this.deletedImages[index]);
      }

      store.dispatch('editProduct', {
        payload: {
          questions: this.questions,
          answers: this.product.answers,
          product: this.product,
        },
        event: this.event,
        exhibitor: this.exhibitor,
      });

      const unsubscribe = store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'editProduct') {
          this.loading = false;

          if (mutation.payload.allActionsSuccessful) {
            this.productCategories.forEach(async (productCategory) => {
              if (productCategory.active) {
                await addProductCategory({
                  category: productCategory.id,
                  product: this.productItem.id,
                });
              } else if (this.productItem.categories) {
                const pCategory = this.productItem.categories.find(
                  (pCategory) => pCategory.category === productCategory.id,
                );
                if (pCategory) {
                  await deleteProductCategory({
                    category: pCategory.id,
                    product: this.productItem.id,
                  });
                }
              }
            });
            this.productSubCategories.forEach(async (productSubCategory) => {
              if (productSubCategory.active) {
                await addProductSubCategory({
                  subcategory: productSubCategory.id,
                  product: this.productItem.id,
                });
              } else if (this.productItem.categories) {
                const pSubCategory: IProductSubcategory | undefined = this.productItem.categories
                  .filter((pCategory) =>
                    pCategory.subcategories.some(
                      (_subCategory) => _subCategory.subcategory === productSubCategory.id,
                    ),
                  )
                  .map((pCategory) =>
                    pCategory.subcategories.find(
                      (sCategory) => sCategory.subcategory === productSubCategory.id,
                    ),
                  )[0];
                if (pSubCategory) {
                  await deleteProductSubCategory({
                    subcategory: pSubCategory.id,
                    product: this.productItem.id,
                  });
                }
              }
            });
            store.commit('addPopup', {
              message: this.translations.common.saved,
              type: 'success',
              autohide: true,
            });

            router.push(
              `/event/${this.event}/manage-exhibitor/${this.exhibitor}/product/${this.productId}`,
            );
          }
          store.dispatch('fetchProducts', {
            event: this.event,
            page: 1,
            page_size: 10,
            exhibitor: this.exhibitor,
          });
          unsubscribe();
        }

        if (mutation.type === 'setProductError') {
          this.loading = false;
          store.commit('addPopup', {
            message: this.translations.common.something_went_wrong,
            type: 'danger',
            autohide: true,
          });
        }
      });
    },
  },
});
